<template>
  <validation-observer
    ref="componentScoreSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="componentScoreSaveModal"
      body-class="position-static"
      size="lg"
      centered
      no-close-on-backdrop
      :title="isCreated ? 'Thêm điểm thành phần' : 'Cập nhật điểm thành phần'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên điểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên điểm"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetData.name"
                  name="name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="generalScoreId">
              <template v-slot:label>
                Điểm chung <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm chung"
                rules="required"
              >
                <v-select
                  v-model="targetData.generalScoreId"
                  label="name"
                  :options="generalScores"
                  :reduce="option => option.id"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Áp dụng từ số TC <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Áp dụng từ số TC"
                rules="required|double"
              >
                <b-form-input
                  id="name"
                  v-model="targetData.minThreshold"
                  name="name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="targetData.isAttendance"
              value="1"
              unchecked-value="0"
            >
              Là điểm chuyên cần
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="orderNo">
              <template v-slot:label>
                Thứ tự <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thứ tự"
                rules="required"
              >
                <b-form-input
                  id="orderNo"
                  v-model="targetData.orderNo"
                  name="orderNo"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('componentScoreSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BFormInput, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, double } from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { STATUSES } from '@/const/status'

export default {
  name: 'ComponentScoreSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    scoringFormulaId: {
      type: Number,
      default: null,
    },
    generalScoreId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        name: '',
        minThreshold: null,
        generalScoreId: null,
        isAttendance: 0,
        status: 1,
        orderNo: null,
      },
      required,
      double,
    }
  },
  computed: {
    ...mapGetters({
      maxOrderNo: 'componentScore/maxOrderNo',
      generalScores: 'componentScore/generalScores',
    }),
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'componentScore/createData',
      updateData: 'componentScore/updateData',
      getMaxOrderNo: 'componentScore/getMaxOrderNo',
      getByScoringFormula: 'componentScore/getByScoringFormula',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await this.getByScoringFormula({ scoringFormulaId: this.scoringFormulaId })
        if (this.isCreated) {
          this.targetData.generalScoreId = this.generalScoreId
          try {
            await this.getMaxOrderNo({ generalScoreId: this.generalScoreId })
            this.targetData.orderNo = this.maxOrderNo + 1
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.targetData = { ...this.mainData }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .componentScoreSaveFormRef
        .reset()
      this.targetData = {
        name: '',
        minThreshold: null,
        generalScoreId: null,
        isAttendance: 0,
        status: 1,
        orderNo: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .componentScoreSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          this.targetData.minThreshold = +this.targetData.minThreshold
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('componentScoreSaveModal')
              } else {
                await this.getMaxOrderNo({ generalScoreId: this.generalScoreId })
                this.targetData.orderNo = this.maxOrderNo + 1
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
